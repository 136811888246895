/* .switch {
    position: relative;
    display: inline-block;
    width: 40px !important;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #A91F6E;
    border: 1px solid #ffffff;

    transition: 0.3s;

} */
/* 
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: #ffffff;
    transition: 0.3s;
}

input:checked + .slider {
    background-color: #ffffff;
}

input:checked + .slider:before {
    transform: translateX(19px);
    background-color: #A91F6E;
}

.slider.rounded {
    border-radius: 34px !important;
}

.slider.rounded::before {
    border-radius: 50%;
} */

.slider_slide{
    width: 40px;
}