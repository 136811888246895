* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  text-transform: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}


::-webkit-scrollbar {
  width: 6px;
  /* Adjust the width as needed */
  /* background-color: black; */
}

/* Set the styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the thumb */
  border-radius: 5px;
  /* Rounded corners for the thumb */
}

/* Set the styles for the scrollbar track on hover */
::-webkit-scrollbar-track:hover {}

/* Set the styles for the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {}

@media (max-width: 769px) {
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
}

.light-table .ant-table-content {
  scrollbar-width: none !important;
}

.dark-table .ant-table-content {
  scrollbar-width: none !important;
}

.dark-table .ant-table-thead tr th {
  background-color: #1F242F !important;
  color: #CECFD2 !important;
  font-weight: 400 !important;
  border: none !important;
}

.light-table .ant-table-thead tr th {
  background-color: #EDEEF1 !important;
  color: #464C5E !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  /* border: none !important; */
}

.light-table .ant-table-tbody tr td {
  background-color: #FFFFFF !important;
  color: #464C5E !important;
  /* border-bottom: 0.3px solid #1F242F !important; */
}

.dark-table .ant-table-tbody tr td {
  background-color: #0C111D !important;
  color: #94969C !important;
  border-bottom: 0.3px solid #1F242F !important;
}

.light-table .ant-pagination .ant-pagination-item-active {
  font-weight: 500 !important;
  /* background-color: #F05D24 !important; */
  border: none !important;
  transform: scale(1);

}

.light-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  /* background-color: #151515 !important; */
  /* border-color: #151515; */
  /* border-radius: 15px; */
  margin: 0 0.5rem;
}

.dark-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  background-color: #fff !important;
  /* border-color: #151515; */
  margin: 0 0.5rem !important;
}

.light-table .ant-pagination .ant-pagination-item-active a {
  background-color: #EEF8FF !important;
  border-radius: 8px !important;
  border: none !important;
}

.light-table .ant-pagination .ant-pagination-item a {
  color: #24262D !important;
}

.dark-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  background-color: transparent !important;
  border-color: none !important;
  margin: 0 0.5rem;
}

.dark-table .ant-pagination .ant-pagination-item-active a {
  color: #cbcbcb !important;
  background-color: #1F242F !important;
  border-radius: 8px !important;
}

.dark-table .ant-pagination .ant-pagination-item a {
  color: #cbcbcb !important;
}

.react-datepicker__input-container {
  display: flex !important;
  align-items: center !important;
}

.light-theme .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
}

.errortheme .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
  top: 16px !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 3px 24px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0 24px !important;
}

.time-picker-dark .react-time-picker__inputGroup input {
  color: #FFFFFF !important;
  /* Dark theme text color */
}

.time-picker-light .react-time-picker__inputGroup input {
  color: #161b26 !important;
  /* Light theme text color */
}

.time-picker-dark .react-time-picker__inputGroup__amPm {
  color: #FFFFFF !important;
  /* Change to your preferred color for dark theme */
}

/* For light theme */
.time-picker-light .react-time-picker__inputGroup__amPm {
  color: #161b26 !important;
  /* Change to your preferred color for light theme */
}

.th.ant-table-cell.ant-table-selection-column {
  border-start-start-radius: 0;
}

.border-border:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0 !important;
}

.border-border:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
  background-color: transparent !important;
}

:where(.css-1k979oh).ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 20px !important;
  /* margin-bottom: 2rem !important */
}

:where(.css-1k979oh).ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 20px !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination::after {
  display: none !important;
}

:where(.css-1k979oh).ant-pagination::after {
  display: none !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-options {
  display: none !important;
}

:where(.css-1k979oh).ant-pagination .ant-pagination-options {
  display: none !important;
}

.dark-table .ant-table-wrapper .ant-table-pagination-right {
  background-color: #0C111D !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item-active {
  border: none !important;
}

:where(.css-1k979oh).ant-pagination .ant-pagination-item-active {
  border: none !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

:where(.css-1k979oh).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth600px.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

:where(.css-1k979oh).ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1k979oh).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: 1px solid transparent !important;
}

:where(.css-1k979oh).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-1k979oh).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: 1px solid transparent !important;
}

.dark-table .ant-table-thead>tr>th,
.dark-table .ant-table-thead>tr>td {
  background-color: #1F242F !important;
  /* Dark background */
}

/* Light theme styles */
.light-table .ant-table-thead>tr>th,
.light-table .ant-table-thead>tr>td {
  background-color: #EDEEF1 !important;
  /* Light background */
}

/* General scrollbar styling that applies to any theme */
.ant-table-content::-webkit-scrollbar {
  width: 6px;
  /* Width of the vertical scrollbar */
  height: 6px;
  /* Height of the horizontal scrollbar */
}

/* Light theme scrollbar styles */
.light-table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1 !important;
  /* Light grey for the thumb */
  border-radius: 5px;
}

.light-table .ant-table-content::-webkit-scrollbar-track {
  background-color: #f0f0f0 !important;
  /* Very light grey for the track */
}

/* Dark table scrollbar styles */
.dark-table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #555 !important;
  /* Darker grey for the thumb */
  border-radius: 5px;
}

.dark-table .ant-table-content::-webkit-scrollbar-track {
  background-color: #333 !important;
  /* Even darker grey for the track */
}

/* Hover styles for scrollbars in both themes */
.ant-table-content::-webkit-scrollbar-thumb:hover {
  background-color: #767676 !important;
}

.ant-table-selection-column {
  padding-left: 30px !important;
  width: 50px !important;
}

/* To make sure the checkbox is centered after changing padding */
.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.light-table .ant-pagination-item-ellipsis {
  color: #565E73 !important;
}

.dark-table .ant-pagination-item-ellipsis {
  color: #85888E !important;
}

.light-table .ant-table-body {
  background-color: #FFFFFF !important;
}

.dark-table .ant-table-body {
  background-color: #0C111D !important;
}

#pic,
#imAge,
#picos {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  display: none !important;
}