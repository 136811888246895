.css-12rl710-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
    border-radius: 15px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 27% !important;
    border-radius: 15px !important;
}

.css-uhb5lp {
    width: 100% !important;
    border-radius: 15px !important;
}

.css-1qmc5dd {
    width: 100% !important;
    border-radius: 15px !important;
}

.light-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth400px.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
    max-width: 500px !important;
    border-radius: 12px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A;

}

.dark-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth400px.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
    max-width: 500px !important;
    border-radius: 12px !important;
    background-color: #161B26 !important;
    box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
}

.light-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth400px.css-1y6pnir {
    max-width: 500px !important;
    border-radius: 12px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A;
}
.dark-modal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth400px.css-1y6pnir {
    max-width: 500px !important;
    border-radius: 12px !important;
    background-color: #161B26 !important;
    box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
    overflow-x: hidden;
}