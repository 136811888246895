.custom-marker {
    position: absolute;
    /* top: 0; */
    width: 8px;
    height: 8px;
    margin-left: -4px;
    margin-top: -25px;
    background-color: #f6591c;
    border-radius: 50%;
    cursor: pointer;
  }